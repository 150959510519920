*,
*::before,
*::after {
    box-sizing: inherit;
    font-family: 'Poppins', sans-serif;
}

html {
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    padding: 0;
    margin: 0;
    overflow-x: inherit;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #00afefcb;
}

::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
}